<template>
  <div class="bg-white xs:px-4 sm:px-6 lg:px-12 xs:py-8 lg:py-18 lg:overflow-hidden app-featured-container relative">
    <section
      class="mx-auto max-w-6.5xl app-featured-section xs:px-6 lg:px-18 lg:pl-0 xs:pt-0 lg:pt-[43px] overflow-hidden"
    >
      <SectionHeader :title="title" :subtitle="description" :type="'app-featured'" />
      <div
        class="relative bg-blue-50 rounded-lg xs:px-6 lg:px-18 xs:py-8 lg:py-18 xs:-mx-6 lg:-mx-18 xs:mt-8 lg:mt-16 overflow-hidden"
      >
        <div class="grid gap-8 xs:grid-cols-1 lg:grid-cols-2 lg:mt-12.5 relative z-1">
          <div class="mx-auto sm:max-w-2xl sm:text-center lg:flex lg:items-center lg:text-left order-2">
            <div>
              <dl>
                <button
                  class="flex text-left flex-col space-y-3 xs:px-6 lg:px-8 pb-4.5 relative overflow-hidden rounded-lg cursor-pointer"
                  :class="[isActive(index) ? 'bg-blue-900 mb-7' : '']"
                  v-for="({ id, title, text }, index) in items"
                  :key="id"
                  @click="open(index as number)"
                >
                  <dt :class="['font-alt h4 pt-8', isActive(index) ? 'text-white' : 'text-black']" v-text="title" />
                  <!--CollapseTransition :duration="3000"-->
                  <dd
                    :class="['text-base pb-3.5', isActive(index) ? 'block text-blue-300' : 'hidden text-gray-600']"
                    v-text="text"
                  />
                  <!--/CollapseTransition-->

                  <div
                    class="absolute bottom-0 left-0 right-0 h-[5px]"
                    :class="isActive(index) ? 'opacity-100' : 'opacity-0'"
                  >
                    <div
                      class="h-full bg-blue-400"
                      :style="`transition-duration: ${transitionDuration}ms`"
                      :class="isActive(index) ? 'app-featured-card-active' : ''"
                    />
                  </div>
                </button>
              </dl>
              <!--div class="mt-10">
                <DecoratedLink type="base" href="/" text="Discover all platform features" icon-name="arrow-up-right" icon="right-icon" weight="semibold" />
              </div-->
            </div>
          </div>
          <div class="relative xs:mt-10 lg:mt-0 -order-1">
            <div class="sm:mx-auto sm:max-w-2xl lg:max-w-none lg:px-0">
              <img
                v-if="currentImage"
                :src="getImageUrl(currentImage)"
                class="xs:w-full xs:h-auto lg:absolute lg:inset-y-0 lg:right-0 lg:w-auto lg:h-auto lg:top-1/2 lg:-translate-y-1/2"
              />
            </div>

            <div class="absolute inset-0 z-[-1] -my-8 -mx-12 lg:-my-12">
              <img
                :src="getImageUrl('app-featured-background.svg')"
                class="w-full h-full object-contain"
                alt="app featured background"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import { AppFeaturedItemsType } from '@/types/app-featured-types'
import { getImageUrl } from '~/utils'

const props = defineProps({
  title: {
    type: String as PropType<string>,
    default: '',
  },
  description: {
    type: String as PropType<string>,
    default: '',
  },
  activeItem: {
    type: Number,
    default: 0,
  },
  items: {
    type: Array as PropType<AppFeaturedItemsType>,
    default: () => [],
  },
})

const items = ref(props.items)

const transitionDuration = 6000
const animationDuration = transitionDuration + 'ms'

const activeIndex = ref<number>(-1)
const currentImage = computed(() => items.value[activeIndex.value]?.image)

const isActive = (index: number | string) => activeIndex.value === index

let interval: any
function newInterval() {
  if (interval) clearInterval(interval)
  interval = setInterval(() => {
    activeIndex.value = activeIndex.value + 1 <= items.value.length - 1 ? activeIndex.value + 1 : 0
  }, transitionDuration)
}

const open = (index: number) => {
  if (activeIndex.value === index) return
  activeIndex.value = index
  newInterval()
}

onMounted(() => {
  nextTick(() => {
    activeIndex.value = props.activeItem
  })

  newInterval()
})
</script>

<style lang="scss">
.app-featured-card-active {
  animation-duration: v-bind(animationDuration);
  animation-name: app-featured-animation;
  animation-direction: normal;
  animation-timing-function: linear;
}

@keyframes app-featured-animation {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}
</style>
